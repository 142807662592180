<template>
    <div>
        <seamless-access-button @click.native="loginWithInstitution()" class="mb-4" v-if="!SATOSAFailed" />
        <google-oauth-btn :redirect="redirectTo + '/google'">Sign up with Google</google-oauth-btn>
        <div class="d-flex align-center mt-5 mb-2" v-if="!SATOSAFailed">
            <v-divider />
            <span class="caption px-2">OR</span>
            <v-divider />
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
const SeamlessAccessButton = () => import('@/components/SeamlessAccessButton.vue')
const GoogleOauthBtn = () => import('@/components/GoogleOauthBtn.vue')

export default {
    components: { SeamlessAccessButton, GoogleOauthBtn },
    props: {
        redirectTo: {
            type: String,
            default: location.pathname
        }
    },
    data() {
        return {
            SATOSAFailed: sessionStorage.getItem('nvSATOSALoginFailed') === 'failed'
        }
    },
    methods: {
        loginWithInstitution() {
            sessionStorage.setItem('nvBeforeSATOSALogin', document.location.href)
            this.$auth.loginWithRedirect({
                connection: this.$route.query.auth_staging === '1' ? 'STAGING' : 'SATOSA',
                appState: { targetUrl: this.redirectTo }
            })
        }
    }
}
</script>
